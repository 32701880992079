<template>
  <v-container
    fluid
    class="container-bg rounded elevation-3">
    <v-row>
      <v-col
        cols="12"
        md="auto">
        <h2>Unit of measures</h2>
      </v-col>
      <v-spacer />
      <v-col
        cols="12"
        md="auto">
        <div class="d-flex flex-row justify-end align-center">
          <search-box
            v-model="query.search"
            @on-search="getItems(true)" />
          <v-btn
            color="success"
            :disabled="loading"
            to="/purchases/uom/create"
            class="ml-2 mb-1">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :item-per-page="query.limit"
          :loading="loading"
          hide-default-footer>
          <template #[`item.action`]="{ item }">
            <v-btn
              color="secondary"
              small
              icon
              :to="`/purchases/uom/${item.id}`">
              <v-icon small>
                mdi-square-edit-outline
              </v-icon>
            </v-btn>
            <v-btn
              color="error"
              small
              icon
              @click="confirmRemoveItem(item)">
              <v-icon small>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination
          v-model="query.page"
          :length="pageCount"
          :disabled="loading"
          total-visible="7"
          color="secondary" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import SearchBox from '@/components/SearchBox.vue'
import UnitOfMeasuresProvider from '@/resources/UnitOfMeasuresProvider'

const UnitOfMeasuresService = new UnitOfMeasuresProvider()

export default {
  components: {
    SearchBox
  },
  data () {
    return {
      headers: [
        {
          text: 'รหัส',
          value: 'code',
          sortable: false
        },
        {
          text: 'ชื่อ',
          value: 'name',
          sortable: false
        },
        {
          text: 'ตัวเลือก',
          value: 'action',
          sortable: false
        }
      ],
      items: [],
      query: {
        page: 1,
        limit: 10,
        search: ''
      },
      pageCount: 0,
      loading: false
    }
  },
  watch: {
    'query.page': {
      handler () {
        this.getItems()
      },
      deep: true
    }
  },
  mounted () {
    this.getItems(true)
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal'
    }),
    async getItems (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.query.page = 1
          this.items = []
        }

        const { data } = await UnitOfMeasuresService.getItems(this.query)

        this.items = data.results
        this.pageCount = data.pages
      } catch (error) {
        console.error('getItems', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    confirmRemoveItem (item) {
      this.setModal({
        value: true,
        title: 'Remove Item',
        message: `Do you want to remove ${item.code}?`,
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.removeItem(item.id)
      })
    },
    async removeItem (id) {
      try {
        this.loading = true

        await UnitOfMeasuresService.deleteItem(id)

        this.getItems(true)
      } catch (error) {
        console.error('removeItem', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.container-bg {
  background-color: white;
}
</style>
